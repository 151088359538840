.tableOfContents {
  margin-bottom: 8rem;
  padding: 3rem;
  border-top: 2px solid var(--grey4);
  border-bottom: 2px solid var(--grey4);
  a {
    display: flex;
    margin-bottom: 0.8rem;
    &::before {
      content: "";
      width: 0.8rem;
      height: 0.8rem;
      margin-right: 1.2rem;
      transform: translateY(0.8rem);
      border-radius: 50%;
      flex-shrink: 0;
    }
  }
}

.tableOfContentsTitle {
  margin-bottom: 3.5rem;
  font-weight: bold;
  font-size: 1.8rem;
}

.topListItem > a::before {
  background-color: var(--blue8);
}
.secondListItem {
  margin-left: 1.8rem;
}
.secondListItem > a::before {
  border: 2px solid var(--blue8);
}

@media screen and (max-width: 600px) {
  .tableOfContents {
    padding: 1.5rem;
    margin-bottom: 4rem;
  }
  .tableOfContentsTitle {
    margin-bottom: 2rem;
  }
}
